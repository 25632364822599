import { GameState } from "./Game";
import LetterDrop from "./LetterDrop";
import { useCallback, useEffect, useState, useRef } from "react";


interface PassageProps {
  answer: String;
  solved: Function;
}

export function Passage(props: PassageProps) {
  const [answer, setAnswer] = useState<String>(props.answer);
  const [foundCount, setFoundCount] = useState<number>(0);
  const foundCountRef = useRef(0);
  foundCountRef.current = foundCount;
  const parentSolved = props.solved;
  const cachedsolved = useCallback(solved, [solved])

  function solved(): void {
    parentSolved();
  }
  const isFound = () => {
    setFoundCount(foundCountRef.current + 1);
  }
  const cachedIsFound = useCallback(isFound, [isFound])
  
  useEffect(() => {
    if (3 === foundCount) {
      cachedsolved();
    }
  },[foundCount,cachedsolved])
  
  return (
    <div
        key={"p"}
        className="word"
      >
      <div className="Row Row-locked-in">
      <LetterDrop
        isFoundFunction={cachedIsFound}
        letter={answer[0]}
        key={0}
      />
      <LetterDrop
        isFoundFunction={cachedIsFound}
        letter={answer[1]}
        key={1}
      />
      <LetterDrop
        isFoundFunction={cachedIsFound}
        letter={answer[2]}
        key={2}
      />
      <p></p>
    </div>
  </div>
  );
}
