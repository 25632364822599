import { useState, useRef}  from 'react'
import { iSoundLetter } from './SoundLetter'
import { ItemTypes } from './Constants'
import { DropTargetMonitor, useDrop } from 'react-dnd'

interface LetterDropProps {
    letter: string;
    key: number;
    isFoundFunction: Function 
  }

function LetterDrop(props: Readonly<LetterDropProps>) {
  const [isFound, setIsFound] = useState<boolean>(false);
  const foundRef = useRef(false);
  foundRef.current = isFound;
  function canDrop(item: any, monitor: DropTargetMonitor<Object, void>) {
      let soundLetter: iSoundLetter = monitor.getItem<iSoundLetter>();
      return !foundRef.current && soundLetter.letter === props.letter
  };

  function dropLetter() {
      if (!isFound) {
          setIsFound(true); 
          props.isFoundFunction();
      }
      
  }

  const [{ isOver }, drop] = useDrop(() => ({
  accept: ItemTypes.SOUND_LETTER,
      drop: () => dropLetter(),
      canDrop: (item, monitor) => canDrop(item,monitor),
      collect: (monitor) => ({
      isOver: monitor.isOver(),
  }),
  }), [])


    let letterClass = "Row-letter " + (isFound ? "": "hidden");

  return (
      <div
      key={props.key + "w"}
                   className={letterClass}
                   aria-live={"off"}
      ref={drop}
      >
          {isFound && props.letter}
         
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  )
}

export default LetterDrop


