import useSound from 'use-sound';
import { soundsprite } from "./soundSprite"

interface LetterProps {
    letters: String;
}
  
function Answer (props: Readonly<LetterProps>)  {    
    const [play] = useSound('sounds/letters2.mp3',
        {
            sprite: soundsprite
        });
            
    const handleClick = () => {
        for (let i = 0; i < props.letters.length; i++) {
            if (i === 0) {
                play({ id: props.letters[i] });
            } else {
                const millisecondsToWait = 600 * i;
                setTimeout(function() {
                    play({ id: props.letters[i] });
                }, millisecondsToWait);    
            }
            
        };
    }
        
    return (
        <button className="soundLetter" onMouseDown={handleClick} >
            🗣
        </button>
  );
};


export default Answer;