import { useEffect, useState, useCallback} from "react";
import { Passage } from "./Passage";
import Answer from "./Answer";
import { Keyboard } from "./Keyboard";

export enum GameState {
  Playing,
  Won,
  Lost,
}

interface GameProps {
  target: string;
  done: Function;
}

function Game(props: Readonly<GameProps>) {
  const [gameState, setGameState] = useState<GameState>(GameState.Playing);
  const [target, setTarget] = useState<String>( props.target);
  const [hint, setHint] = useState<string>('Make your first guess!');

  useEffect(() => {
    setHint('Make your first guess!');
    setGameState(GameState.Playing);
    setTarget(props.target);
  }, [props.target]);

  const cachedSolved = useCallback(solved, [solved])
  
  function solved(): void {
    setGameState(GameState.Won);
  }

  return (
    <div className="Game" style={{ display:"block" }}>
      
      <div
        className="Game-rows"
        aria-label="Word"
      >
        
        {
          (gameState === GameState.Playing && 
          (<>
            <Passage
              key={0}
              answer={target}
              solved={cachedSolved}
            />
            <Answer letters={target} />
            <br />
            <Keyboard />
            <p
              role="alert"
              style={{
                userSelect: /https?:/.test(hint) ? "text" : "none",
                whiteSpace: "pre-wrap",
              }}
            >
              {hint || `\u00a0`}
            </p>
          </>))
          ||
          (<>
            <h1>You got it! </h1>

            <button onClick={() => props.done()} >Start another</button>
          </>)
        }
      </div>
      
    </div>
  );
}

export default Game;
