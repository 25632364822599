import "./App.css";
import { pickRandom } from "./util";
import Game from "./Game";
import { useEffect, useState, Suspense, useRef } from "react";
import Loading from "./Loading";
import targetList from "./words.json";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const targets = targetList;

function App() {
  type Page = "game";
  const [page, setPage] = useState<Page>("game");
  const [target, setTarget] = useState<string>("");
  const targetRef = useRef("");
  targetRef.current = target;
  useEffect(() => {
    setTimeout(() => {
      // Avoid transition on page load
      document.body.style.transition = "0.3s background-color ease-out";
    }, 1);
    setTarget(pickRandom(targets));
  }, []);

  function done(): void {
    console.log("Starting a new game, this one is done!");
    setTarget(pickRandom(targets));   
  }

  return (
    <Suspense fallback={<Loading />}>
      <DndProvider debugMode={true}  backend={HTML5Backend}>
        <div className={"App-container"}>
          <h1>
            CVC
          </h1>
          <div className="top-left">          </div>
          <div className="top-right">
          </div>
          <div
            style={{
              position: "absolute",
              left: 5,
              top: 5,
              visibility: page === "game" ? "visible" : "hidden",
            }}
          >
          </div>
        
          {
            (target.length > 0 &&
              <Game
              target={targetRef.current}
              done={done}
              />) || 'Loading...'
          }
        </div >
        </DndProvider>
      </Suspense>

  );
}

export default App;


