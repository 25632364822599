import SoundLetter from "./SoundLetter";

export function Keyboard() {
  const keyboard: React.InputHTMLAttributes<HTMLInputElement>
   = (
    <div
      key={"keyboard"}
      className={"karltest"}
    >
     
      <h3>Vowels</h3>
      <SoundLetter letter="a"/>
      <SoundLetter letter="e"/>
      <SoundLetter letter="i" />
      <SoundLetter letter="o"/>
      <SoundLetter letter="u" />
      <br />
      <h3>Top row consonants</h3>
      <SoundLetter letter="r" />
      <SoundLetter letter="t" />
      <SoundLetter letter="p" />
      <br />
      <h3>Middle row consonants</h3>
      <SoundLetter letter="s"/>
      <SoundLetter letter="d" />
      <SoundLetter letter="f" />
      <SoundLetter letter="g"/>
              <SoundLetter letter="h" />
              <SoundLetter letter="j" />
      <SoundLetter letter="k"/>
      <SoundLetter letter="l" />
      <br />
      <h3>Bottom row consonants</h3>
      <SoundLetter letter="z" />  
      <SoundLetter letter="x" />
      <SoundLetter letter="c" />
      <SoundLetter letter="v" />
      <SoundLetter letter="b" />
      <SoundLetter letter="n" />
      <SoundLetter letter="m" />
    </div>
    )
  
  return (
    <>
      <hr />
      {keyboard}
      </>
  );
}
