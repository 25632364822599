import useSound from 'use-sound';
import { useDrag } from 'react-dnd'
import { ItemTypes } from './Constants';
import { soundsprite } from "./soundSprite"

export interface iSoundLetter {
    letter: string
}
interface LetterProps {
    letter: string;
}
  
function SoundLetter(props: Readonly<LetterProps>)  {    
    const [play] = useSound('sounds/letters2.mp3',
        {
            sprite: soundsprite
        });
            
    const handleClick = () => {
        play({ id: props.letter });
    };
    
    const [, dragRef] = useDrag(
        () => ({
          type: ItemTypes.SOUND_LETTER,
            item: props  ,
          collect: (monitor) => ({
            }),
            beginDrag: function (props: any) {
                // Return the data describing the dragged item
                return { id: props.letter };
              },
        }),
        []
      )

    return (
        <button ref={dragRef} className="soundLetter" onMouseDown={handleClick} >
            {props.letter.toUpperCase()}
        </button>
  );
};


export default SoundLetter;