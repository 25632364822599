import { SpriteMap } from "use-sound/dist/types"

export const soundsprite: SpriteMap = {
    a: [0, 2000],
    b: [2000, 2000],
    '?': [4000, 2000],
    d: [6000, 2000],
    e: [8000, 2000],
    f: [10000, 2000],
    g: [12000, 2000],
    h: [14000, 2000],
    i: [16000, 2000],
    j: [18000, 2000],
    l: [20000, 2000],
    ll: [20000, 2000],
    m: [22000, 2000],
    n: [24000, 2000],
    o: [26000, 2000],
    p: [28000, 2000],
    r: [30000, 2000],
    s: [32000, 2000],
    '.': [34000, 2000],
    t: [36000, 2000],
    u: [38000, 2000],
    x: [40000, 2000],
    c: [42000, 2000],
    k: [42000, 2000],
    ck: [42000, 2000],
    v: [44000, 2000],
    z: [46000, 2000]
}